import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import Contact from '../Components/Home/Contact';
import Education from '../Components/Home/Education';
import Experience from '../Components/Home/Experience';
import Footer from '../Components/Footer';
import Hero from '../Components/Home/Hero';
import NavBar from '../Components/NavBar';
import Skills from '../Components/Home/Skills';
import Carosoul from '../Components/Home/Carosoul';
import About from '../Pages/About';

const MotionSection = ({ id, children }) => {
  return (
    <motion.div
      id={id}
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: false, amount: 0.3 }}
      transition={{ duration: 0.5, ease: "easeOut" }}
    >
      {children}
    </motion.div>
  );
};

const Home = () => {
  const [activeSection, setActiveSection] = useState('Hero');

  useEffect(() => {
    const sections = ['Hero', 'Experience', 'Projects', 'Education', 'Skills', 'About', 'Contact'];
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5,
    };

    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActiveSection(entry.target.id);
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, options);
    sections.forEach((section) => {
      observer.observe(document.getElementById(section));
    });

    return () => {
      sections.forEach((section) => {
        observer.unobserve(document.getElementById(section));
      });
    };
  }, []);

  return (
    <>
      <NavBar activeSection={activeSection} setActiveSection={setActiveSection} />
      <MotionSection id="Hero">
        <div className="hero-container px-4 h-auto">
          <Hero />
        </div>
      </MotionSection>
      
      <MotionSection id="Experience">
        <div className="item experience h-auto px-4 md:px-40 py-8 w-full mt-10">
          <Experience />
        </div>
      </MotionSection>

      <MotionSection id="Projects">
        <div className="projects h-auto px-4 md:px-40 py-8">
          <Carosoul/>
        </div>
      </MotionSection>

      <MotionSection id="Education">
        <div className="education h-auto px-4 md:px-40 py-8">
          <Education />
        </div>
      </MotionSection>

      <MotionSection id="Skills">
        <div className="skills h-auto px-4 md:px-40 py-8 w-full">
          <Skills />
        </div>
      </MotionSection>

      <MotionSection id="About">
        <div className="about h-auto px-4 md:px-40 py-8">
          <About />
        </div>
      </MotionSection>

      <MotionSection id="Contact">
        <div className="contact h-auto px-4 md:px-40 py-8">
          <Contact />
        </div>
      </MotionSection>

      <Footer />
    </>
  );
};

export default Home;