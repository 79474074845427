import React, { useEffect, useRef } from 'react';
import TRUNK from 'vanta/dist/vanta.trunk.min';
import p5 from 'p5';
import resume from './../../Assets/Gangey_Patel_Resume.pdf';
import { color } from 'framer-motion';
import { FaLinkedin,FaGithub,FaTwitter } from 'react-icons/fa';
import { SiChessdotcom, SiGithub, SiInstagram, SiLeetcode, SiLinkedin, SiSpotify, SiTwitter } from 'react-icons/si';

function openPDF() {
  window.open(resume, '_blank');
}

function sendEmail(event) {
  event.preventDefault();
  window.location.href = "mailto:gangey.apatel@gmail.com";
}

const Hero = () => {
  const vantaRef = useRef(null);

  useEffect(() => {
    let vantaEffect;
    if (vantaRef.current) {
      vantaEffect = TRUNK({
        el: vantaRef.current,
        p5: p5,
        mouseControls: false,
        touchControls: false,
        gyroControls: false,
        minHeight: 200.00,
        minWidth: 200.00,
        scale: 1.00,
        scaleMobile: 1.00,
        spacing:7.00,
        chaos: 9.00,
        backgroundColor: 0xe6ebe0,
        color: 0xF1AE8B,
        opacity: 0.1

            });
    }

    return () => {
      if (vantaEffect) vantaEffect.destroy();
    };
  }, []);

  return (
    <div ref={vantaRef} id="herolal" className="min-h-full flex relative">
      <div className="absolute top-40 md:top-20 right-0 p-10 text-[#1b4965]">
        <div className="intro-section bottom-0 left-0 ">
          <h1 className="sm:text-lg text-5xl lg:text-9xl md:mb-2">
            Hello, I am <span className='text-[#ED6A5A]'>Gangey Patel</span> aka GG.
          </h1>
          <h2 className="text-xl md:text-3xl">
          A friendly and passionate software engineer, ready to craft innovation.
          </h2>
        </div>
      </div>
      <div className="absolute bottom-0 right-0 p-10 mb-10 md:mb-0 text-[#1b4965]">
        <p className="text-sm md:text-xl mb-4 max-w-xl">
          I am a highly motivated and results-oriented software developer with a Master's degree in Computer Science from Arizona State University. I am eager to leverage my skills and knowledge to contribute to a fast-paced and innovative software engineering team. I am a quick learner and a team player with a strong work ethic.
        </p>
        <div className="flex space-x-4">
          <button className='btn bg-[#1b4965] text-[#e6ebe0] mt-5 p-2 hover:text-[#1b4965] hover:bg-[#e6ebe0] hover:border-[#1b4965] transition duration-200 rounded-lg px-4 py-2' onClick={sendEmail}>Hire me!</button>
          <button className='btn bg-[#1b4965] text-[#e6ebe0] mt-5 p-2 hover:text-[#1b4965] hover:bg-[#e6ebe0] hover:border-[#1b4965] rounded-lg px-4 py-2' onClick={openPDF}>Resume</button>
        </div>
        
      </div>
      <div className="absolute bottom-0 left-0 p-10 ml-1 space-x-4 text-[#1b4965] text-center flex">
        <a href="https://www.linkedin.com/in/gangeyp" target="_blank" rel="noopener noreferrer" className="text-2xl">
        <SiLinkedin color="#0b3954" size="1rem"/>
        </a>
        <a href="https://github.com/gangeypatel" target="_blank" rel="noopener noreferrer" className="text-2xl">
          <SiGithub color="#0b3954" size="1rem"/>
        </a>
        <a href="https://twitter.com/GangeyP" target="_blank" rel="noopener noreferrer" className="text-2xl">
          <SiTwitter color="#0b3954" size="1rem"/>
        </a>
        <a href="https://instagram.com/akabheeshma" target="_blank" rel="noopener noreferrer" className="text-2xl">
          <SiInstagram color="#0b3954" size="1rem" />
        </a>
        <a href="https://link.chess.com/friend/BJJEa7" target="_blank" rel="noopener noreferrer" className="text-2xl">
          <SiChessdotcom color="#0b3954" size="1rem" />
        </a>
        <a href="https://open.spotify.com/user/31w32a4d5jo2w2y53qvioyezczsi?si=e9e5a2c5930b41ef" target="_blank" rel="noopener noreferrer" className="text-2xl">
          <SiSpotify color="#0b3954" size="1rem" />
        </a>
        <a href="https://leetcode.com/GangeyP" target="_blank" rel="noopener noreferrer" className="text-2xl">
          <SiLeetcode color="#0b3954" size="1rem" />
        </a>
      </div>
    </div>
  );
}

export default Hero;