import React from 'react'

const Title = ({Title}) => {
  return (
    <div className="title text-5xl lg:text-6xl lg:mt-10 lg:pb-20 px-4 lg:px-10 flex justify-center  ">
        <h1>{Title}</h1>
    </div>
  )
}

export default Title