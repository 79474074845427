import React from 'react';
import { motion } from "framer-motion";

const ProjectCard = ({ Year, Title, Description, Technologies, Link }) => {
  return (
    <motion.div className="shadow-div card w-80 h-[27rem] border-2 border-[#5D576B] m-2 carousel-item  rounded-xl" whileHover={{ x:-7, y:-7 }}>
      <div className="card-body">
        <div className="flex flex-row">
        <h2 className="card-title text-2xl text-[#ED6A5A] w-5/6">{Year}</h2>
        <a className="link flex justify-end w-1/6 pt-1" href={Link} target="blank">link</a></div>
        <h2 className="card-title text-2xl ">{Title}</h2>
        <p>{Description}</p>
        <div className="card-actions justify-end">
          {Technologies.map((tech, index) => (
            <div key={index} className="badge badge-outline text-[#1b4965]">{tech}</div>
          ))}
        </div>
        
      </div>
    </motion.div>
  );
};

export default ProjectCard;
