import React from 'react';
import Title from '../Title';

const EducationCard = ({ years, institution, degree, gpa, details }) => (
  <div className="education-shadow border-2 rounded-xl border-[#1b4965] p-6 transition-transform h-96 md:h-auto">
    <time className="font-bold text-[#ED6A5A] block mb-2">{years}</time>
    <h3 className="text-2xl font-bold mb-2">{institution}</h3>
    <h4 className="text-lg underline mb-2">{degree}</h4>
    {gpa && <p className="mb-2">{gpa}</p>}
    <p>{details}</p>
  </div>
);

const Education = () => {
  const educationData = [
    {
      years: "2022-2024",
      institution: "Arizona State University",
      degree: "Masters in Computer Science",
      // gpa: "GPA 3.7/4.0",
      details: "Focus on Software Engineering and subjects like Data Visualization, Fundamentals of Statistical Learning, Cloud Computing, Software Validation & testing."
    },
    {
      years: "2018-2022",
      institution: "Indus University",
      degree: "Bachelors in Computer Science",
      // gpa: "GPA 9.32/10",
      details: "Focused on subjects like Programming for problem‑solving, Data structures & algorithms, Design and analysis of algorithms, Theory of Computation, Web Technologies, Finite Language & Automata Theory."
    },
    {
      years: "2016-2018",
      institution: "Gujarat Higher Secondary Education Board",
      degree: "High School",
      details: "Concentrated in Maths, Chemistry and Physics providing foundation for further Education. Secured 2nd rank in the school and 4958th rank in the state with making 96.11 percentile."
    }
  ];

  return (
    <div className=" px-4 md:px-20">
      <Title Title={'Education'} />
      <div className="mt-8 grid gap-6">
        {educationData.map((edu, index) => (
          <EducationCard 
            key={index} 
            {...edu} 
          />
        ))}
      </div>
    </div>
  );
};

export default Education;
