import React from 'react';
import Title from '../Components/Title';
import photo from '../Assets/image.svg';

const About = () => {
  return (
    <>
      <Title Title={'About'} />
      <div className="flex flex-col lg:flex-row w-full items-center lg:items-start">
        <div className="flex justify-center w-full lg:w-2/6 p-4 lg:p-0">
          <div className="avatar flex justify-center p-10">
            <div className="w-48 h-48 lg:w-96 lg:h-full rounded-full">
              <img src={photo} alt="Profile"/>
            </div>
          </div>
        </div>
        <div className="about w-full lg:w-4/6 px-6 lg:px-10 text-base lg:text-xl flex items-center lg:items-start">
          <p>
            Thank you for scrolling this far! I appreciate it. I hope I've done a good job representing myself here. A little more about me...
            <br /><br />
            I am interested in designing websites and UIs that don't suck as well as build robust backend system. So I am learning something new day by day.
            I try to keep me updated by learning new technologies and frameworks.
            I have a strong foundation in computer science and programming, and I am always looking for opportunities to learn and grow.
            <br /><br />
            I am a pretty frindly and joyful person. I love to meet new people and make new friends. Feel free to connect with me on social media, would love to chat.
            Outside of work, I enjoy playing video games on PlayStation & PC (mostly VALORANT lol), reading mangas, watching Anime, listning to music and watching movies. I have a passion for chess.
            I also try to cook new dishes and experiment with indian food (not a good chef). Also like to play volleyball (Thanks to Haikyuu!!) and Badminton.
            <br/><br/>
            That's about it! If you have any questions or just want to chat, feel free to reach out to me. Shoot me an email below or connect with me.
          </p>
        </div>
      </div>
    </>
  );
};

export default About;
