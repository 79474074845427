import React, { useState } from 'react';
import './../global.css';

const NavBar = ({ activeSection, setActiveSection }) => {
  const handleTabClick = (section) => {
    document.getElementById(section).scrollIntoView({ behavior: 'smooth' });
    setActiveSection(section);
  };

  return (
    <div className='nav flex justify-center h-auto'>
      <div role="tablist" className="tabs tabs-boxed bg-[#e6ebe0] border border-[#1b4965] menu menu-horizontal flex justify-center fixed z-50 w-auto mx-4 rounded-xl top-5 h-auto py-2 md:px-6">
        <a 
          role="tab" 
          className={`tab rounded-xl text-[#1b4965] hover:bg-[#1b4965] hover:text-[#e6ebe0] ${activeSection === 'Hero' ? ' bg-[#1b4965] text-[#e6ebe0] rounded-full' : ''}`} 
          onClick={() => handleTabClick('Hero')}
        >
          Home
        </a>
        <a 
          role="tab" 
          className={`tab rounded-xl text-[#1b4965] hover:bg-[#1b4965] hover:text-[#e6ebe0] ${activeSection === 'Experience' ? 'bg-[#1b4965] text-[#e6ebe0]' : ''}`} 
          onClick={() => handleTabClick('Experience')}
        >
          Experience
        </a>
        <a 
          role="tab" 
          className={`tab rounded-xl text-[#1b4965] hover:bg-[#1b4965] hover:text-[#e6ebe0] ${activeSection === 'Projects' ? 'bg-[#1b4965] text-[#e6ebe0]' : ''}`} 
          onClick={() => handleTabClick('Projects')}
        >
          Projects
        </a>
        <a 
          role="tab" 
          className={`tab rounded-xl text-[#1b4965] hover:bg-[#1b4965] hover:text-[#e6ebe0] ${activeSection === 'Education' ? 'bg-[#1b4965]  text-[#e6ebe0]' : ''}`} 
          onClick={() => handleTabClick('Education')}
        >
          Education
        </a>
        <a 
          role="tab" 
          className={`tab rounded-xl text-[#1b4965] hover:bg-[#1b4965] hover:text-[#e6ebe0] ${activeSection === 'Skills' ? 'bg-[#1b4965]  text-[#e6ebe0]' : ''}`} 
          onClick={() => handleTabClick('Skills')}
        >
          Skills
        </a>
        <a 
          role="tab" 
          className={`tab rounded-xl text-[#1b4965] hover:bg-[#1b4965] hover:text-[#e6ebe0] ${activeSection === 'About' ? 'bg-[#1b4965] border text-[#e6ebe0]' : ''}`} 
          onClick={() => handleTabClick('About')}
        >
          About
        </a>
        <a 
          role="tab" 
          className={`tab rounded-xl text-[#1b4965] hover:bg-[#1b4965] hover:text-[#e6ebe0] ${activeSection === 'Contact' ? 'bg-[#1b4965] text-[#e6ebe0]' : ''}`} 
          onClick={() => handleTabClick('Contact')}
        >
          Contact
        </a>
      </div>
    </div>
  );
};

export default NavBar;
