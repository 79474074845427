import React from 'react'
import { SiGithub, SiInstagram, SiLinkedin, SiX } from 'react-icons/si'

const Footer = () => {
  return (
    <>
    <div className="navbar px-4 md:px-40 border-t-4 border-[#1b4965] flex justify-center flex-col mt-4 ">
    <div className="navbar-center">
            <a href="https://www.instagram.com/akabheeshma/"className="btn btn-ghost text-xl" target="blank" ><SiInstagram color="#0b3954" size="1rem"/></a>
            <a href="https://www.github.com/gangeypatel"className="btn btn-ghost text-xl" target="blank"><SiGithub color="#0b3954" size="1rem"/></a>
            <a href="https://www.linkedin.com/in/gangeyp" className="btn btn-ghost text-xl" target="blank"><SiLinkedin color="#0b3954" size="1rem"/></a>
            <a href="https://www.x.com/gangeyp" className="btn btn-ghost text-xl" target="blank"><SiX color="#0b3954" size="1rem"/></a>
    </div>
    <div className='mx-10 text-sm'> Made with 🫶 by <span className='text-[#ED6A5A] underline mx-2'><a href="https://www.github.com/gangeypatel" >GG</a></span></div>
    </div>
    </>
  )
}

export default Footer