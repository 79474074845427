import React from 'react';
import Title from '../Title';

const Contact = () => {
  function sendEmail(event) {
    event.preventDefault();
    var message = document.getElementById('message').value;
    var subject = document.getElementById('subject').value;

    var mailtoLink = "mailto:gangey.apatel@gmail.com" +
        "?subject=" + encodeURIComponent(subject) +
        "&body=" + encodeURIComponent(message);

    window.location.href = mailtoLink;
    window.open(mailtoLink, 'blank');
  }

  return (
    <>
      <Title Title={'Contact Me'}/>
      <form className='w-full md:w-2/3 lg:w-1/2 xl:w-1/3 flex flex-col justify-center m-auto my-10 px-4' onSubmit={sendEmail}>
        <label className="flex flex-col my-2">
          <span className="md:text-[#1b4965]">Subject</span>
          <input type="text" className="input bg-[#e6ebe0] border-[#5D576B] input-bordered mt-1 p-2" id="subject" placeholder="   hey GG" />
        </label>
        <label className="flex flex-col my-2">
          <span className="text-sm md:text-base">Message</span>
          <textarea className="bg-[#e6ebe0] border-[#5D576B] message textarea textarea-bordered mt-1 p-2 h-32" id="message" placeholder="  I like this design"></textarea>
        </label>
        <button className="btn bg-[#1b4965] text-[#e6ebe0] mt-5 p-2 hover:text-[#1b4965] hover:bg-[#e6ebe0] hover:border-[#1b4965]" type="submit">Send</button>
      </form>
    </>
  );
};

export default Contact;
