import React from 'react';
import Footer from '../Components/Footer';
import NavBar from '../Components/NavBar';
import Title from '../Components/Title';
import resume from './../Assets/Gangey_Patel_Resume.pdf';

function openPDF() {
   
  var pdfPath = resume;
  
  window.open(pdfPath, '_blank');
}

const Resume = () => {
  return (
    <>
      <div className="resume px-4 md:px-40 h-auto mt-20 md:mt-40">
        <div className='py-10 lg:py-0'>
            <Title Title={'Resume'}/>
        </div> 
      <div className="flex justify-center my-5">
        <button className='btn btn-error text-[#1b4965] hover:bg-[#094074]' onClick={openPDF}>Full Page</button>
      </div>
      <div className='opacity-0 z-10 md:flex md:opacity-100 md:z-10'>
      <div className="resume-container h-[100vh] w-full md:w-4/5 lg:w-4/5 xl:w-4/5 justify-center mx-auto mb-10">
        <iframe
          src={resume}
          type="application/pdf"
          className="w-full h-screen"
        ></iframe>
      </div>
      </div>
      <div >
        <div className='hidden md:flex'></div>
        </div>
      <Footer/>
      </div>
      
    </>
  );
};

export default Resume;
