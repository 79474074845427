import React from 'react'
import Title from '../Title'

const Experience = () => {
  return (
    <>
      <Title Title={'Experience'}/>
      <div className="flex flex-col w-full px-4 lg:px-10 mt-4">
      <div className="divider"></div> 
        <div className="grid h-auto card rounded-xl place-items-center mb-4 lg:mb-0 lg:mr-4">
          <div className="flex flex-col lg:flex-row items-center">
            <div className="duration  w-full lg:w-1/2 card rounded-box flex justify-center text-3xl lg:text-5xl   pl-4 lg:pl-10">Jun '24 - Present</div>
            <div className="duration h-auto lg:h-auto card rounded-box p-4 lg:p-8 mt-4 lg:mt-0 lg:w-2/3">
              <div className="flex flex-col">
                <h2 className='text-lg lg:text-2xl  '>Full Stack Engineer @ <span className="text-[#ED6A5A]">Epics at ASU - shoptaki</span></h2>
                <h2 className="text-lg lg:text-lg"> New York, NY </h2>
                <p className="text-sm lg:text-base">Working on a smartchain for Identity identification. Primarily engnaged in development of backend system to create robust and accurate Facial and voice recognition with Livliness detection.</p>
              </div>
            </div>
          </div>
        </div> 
        <div className="divider"></div> 
        <div className="grid h-auto card rounded-xl place-items-center mb-4 lg:mb-0 lg:mr-4">
          <div className="flex flex-col lg:flex-row items-center">
            <div className="duration  w-full lg:w-1/2 card rounded-box flex justify-center text-3xl lg:text-5xl   pl-4 lg:pl-10">Jan '22 - Jun '22</div>
            <div className="duration h-auto lg:h-auto card rounded-box p-4 lg:p-8 mt-4 lg:mt-0 lg:w-2/3">
              <div className="flex flex-col">
                <h2 className='text-lg lg:text-2xl  '>Software Engineer Intern @ <span className="text-[#ED6A5A]">Sperotel Technologies</span></h2>
                <h2 className="text-lg lg:text-lg">Ahmedabad,Gujarat,India</h2>
                <p className="text-sm lg:text-base">I contributed significantly to developing an interactive dashboard for real-time UPS system monitoring, effectively minimizing breakdowns. Additionally, I played a role in creating a server program to improve data accuracy and overall system performance.</p>
              </div>
            </div>
          </div>
        </div> 
        <div className="divider"></div> 
        <div className="grid h-auto card rounded-xl place-items-center mb-4 lg:mb-0 lg:mr-4">
          <div className="flex flex-col lg:flex-row items-center">
          <div className="duration  w-full lg:w-1/2 card rounded-box flex justify-center text-3xl lg:text-5xl   pl-4 lg:pl-10">Jun '21 - Nov '21</div>
            <div className="duration h-auto lg:h-auto card rounded-box p-4 lg:p-8 mt-4 lg:mt-0 lg:w-2/3">
              <div className="flex flex-col">
                <h2 className='text-lg lg:text-2xl  '>FrontEnd Developer Intern @ <span className="text-[#ED6A5A]">Cloud Ladder Consulting</span></h2>
                <h2 className="text-lg lg:text-lg">Irving, TX </h2>
                <p className="text-sm lg:text-base">Worked on a B2B service provide website, made changes in UI that resulted in significant user activity, performed white box testing of the whole website, and contributed to the development of the chatbot "Cloudy".</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Experience
