import React from 'react';
import { Tab } from '@headlessui/react';
import { motion } from 'framer-motion'; // Import motion from Framer Motion
import Title from '../Title';
import {
  SiHtml5, SiCss3, SiTailwindcss, SiReact, SiJavascript, SiBootstrap, SiChakraui, SiDaisyui, SiD3Dotjs, SiChartdotjs, SiAmazonecs, SiGit, SiGithub, SiVisualstudiocode, SiJunit5, SiSelenium, SiPycharm, SiEclipseadoptium, SiIntellijidea, SiJupyter, SiSpring, SiFlask, SiDjango, SiNodedotjs, SiExpress, SiAwslambda, SiAmazonaws, SiPostgresql, SiMysql, SiMongodb, SiAmazondynamodb, SiPrometheus, SiGrafana, SiDocker, SiAmazons3, SiPandas, SiNumpy
} from "react-icons/si";
import { FaJava } from 'react-icons/fa';
import { SiPython, SiCplusplus, SiC, SiR, SiCsharp, SiPhp } from 'react-icons/si';

const Skills = () => {
  const categories = {
    Languages: [
      { name: 'Python', icon: <SiPython color='#0b3954' size="4rem" /> },
      { name: 'Java', icon: <FaJava color='#0b3954' size="4rem" /> },
      { name: 'C++', icon: <SiCplusplus color='#0b3954' size="4rem" /> },
      { name: 'C', icon: <SiC color='#0b3954' size="4rem" /> },
      { name: 'R', icon: <SiR color='#0b3954' size="4rem" /> },
      { name: 'C#', icon: <SiCsharp color='#0b3954' size="4rem" /> },
      { name: 'PHP', icon: <SiPhp color='#0b3954' size="4rem" /> },
    ],
    'Front-End': [
      { name: 'HTML', icon: <SiHtml5 color='#0b3954' size="4rem" /> },
      { name: 'CSS', icon: <SiCss3 color='#0b3954' size="4rem" /> },
      { name: 'JavaScript', icon: <SiJavascript color='#0b3954' size="4rem" /> },
      { name: 'React', icon: <SiReact color='#0b3954' size="4rem" /> },
      { name: 'Tailwind', icon: <SiTailwindcss color='#0b3954' size="4rem" /> },
      { name: 'Bootstrap', icon: <SiBootstrap color='#0b3954' size="4rem" /> },
      { name: 'Chakra UI', icon: <SiChakraui color='#0b3954' size="4rem" /> },
      { name: 'Daisy UI', icon: <SiDaisyui color='#0b3954' size="4rem" /> },
      { name: 'D3.js', icon: <SiD3Dotjs color='#0b3954' size="4rem" /> },
      { name: 'Chart.js', icon: <SiChartdotjs color='#0b3954' size="4rem" /> },
    ],
    'Back End': [
      { name: 'Spring', icon: <SiSpring color='#0b3954' size="4rem" /> },
      { name: 'Flask', icon: <SiFlask color='#0b3954' size="4rem" /> },
      { name: 'Django', icon: <SiDjango color='#0b3954' size="4rem" /> },
      { name: 'Node.js', icon: <SiNodedotjs color='#0b3954' size="4rem" /> },
      { name: 'Express.js', icon: <SiExpress color='#0b3954' size="4rem" /> },
      { name: 'Lambda', icon: <SiAwslambda color='#0b3954' size="4rem" /> },
      { name: 'EC2', icon: <SiAmazonecs color='#0b3954' size="4rem" /> },
    ],
    'Databases and DevOps': [
      { name: 'SQL', icon: <SiMysql color='#0b3954' size="4rem" /> },
      { name: 'Postgre', icon: <SiPostgresql color='#0b3954' size="4rem" /> },
      { name: 'MongoDB', icon: <SiMongodb color='#0b3954' size="4rem" /> },
      { name: 'DynamoDB', icon: <SiAmazondynamodb color='#0b3954' size="4rem" /> },
      { name: 'Prometheus', icon: <SiPrometheus color='#0b3954' size="4rem" /> },
      { name: 'Grafana', icon: <SiGrafana color='#0b3954' size="4rem" /> },
      { name: 'Docker', icon: <SiDocker color='#0b3954' size="4rem" /> },
      { name: 'S3', icon: <SiAmazons3 color='#0b3954' size="4rem" /> },
      { name: 'Pandas', icon: <SiPandas color='#0b3954' size="4rem" /> },
      { name: 'Numpy', icon: <SiNumpy color='#0b3954' size="4rem" /> },
    ],
    'Developer tools': [
      { name: 'Git', icon: <SiGit color='#0b3954' size="4rem" /> },
      { name: 'GitHub', icon: <SiGithub color='#0b3954' size="4rem" /> },
      { name: 'VS Code', icon: <SiVisualstudiocode color='#0b3954' size="4rem" /> },
      { name: 'JUnit', icon: <SiJunit5 color='#0b3954' size="4rem" /> },
      { name: 'Selenium', icon: <SiSelenium color='#0b3954' size="4rem" /> },
      { name: 'Pycharm', icon: <SiPycharm color='#0b3954' size="4rem" /> },
      { name: 'IntelliJ', icon: <SiIntellijidea color='#0b3954' size="4rem" /> },
      { name: 'Jupyter', icon: <SiJupyter color='#0b3954' size="4rem" /> },
    ],
  };

  return (
    <>
      <Title Title={'Skills'}/>
      <Tab.Group>
        <Tab.List className="p-1 space-x-1 bg-[#e6ebe0] border border-[#1b4965] rounded-xl my-4 block md:flex md:justify-center">
          {Object.keys(categories).map((category) => (
            <Tab
              key={category}
              className={({ selected }) =>
                `w-full py-2.5 text-sm leading-5 font-medium rounded-lg
                  ${selected ? 'bg-[#1b4965] border text-[#e6ebe0]' : 'hover:bg-[#1b4965] hover:text-[#e6ebe0] hover:border-black'}
                `
              }
            >
              {category}
            </Tab>
          ))}
        </Tab.List>
        
        <Tab.Panels className="mt-2">
          {Object.values(categories).map((skills, idx) => (
            <Tab.Panel
              key={idx}
              className="md:m-20 rounded-xl p-3 my-10"
            >
              <motion.div 
                className="flex flex-wrap justify-center"
                initial={{ opacity: 0, y: 50 }} // Start state
                animate={{ opacity: 1, y: 0 }}   // End state
                transition={{ duration: 0.5 }}   // Animation duration
              >
                {skills.map((skill) => (
                  <motion.div 
                    key={skill.name} 
                    className="m-2 md:m-9 flex flex-col items-center"
                    whileHover={{ scale: 1.1, rotate: 5 }} // Hover effect
                    transition={{ type: 'spring', stiffness: 300 }}
                  >
                    {skill.icon}
                    <p className="text-center text-sm mt-1">{skill.name}</p>
                  </motion.div>
                ))}
              </motion.div>
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </>
  );
};

export default Skills;
