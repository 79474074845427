import React, { useRef } from 'react';
import Title from '../Title';
import ProjectCard from '../Projects/ProjectCard';
import Projects from './../Projects/Projects.json';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';

const Carosoul = () => {
  const carouselRef = useRef(null);

  // Scroll left function
  const scrollLeft = () => {
    if (carouselRef.current) {
      carouselRef.current.scrollBy({
        left: -300, // Adjust scroll distance as needed
        behavior: 'smooth',
      });
    }
  };

  // Scroll right function
  const scrollRight = () => {
    if (carouselRef.current) {
      carouselRef.current.scrollBy({
        left: 300, // Adjust scroll distance as needed
        behavior: 'smooth',
      });
    }
  };

  return (
    <>
      <Title Title={'Projects'} />
      <div className="relative max-w-full flex items-center">
        {/* Left arrow (hidden on small screens) */}
        <button
          onClick={scrollLeft}
          className="hidden md:block absolute left-[-2rem] top-1/2 transform -translate-y-1/2 border border-[#1b4965] bg-[#e6ebe0] text-[#1b4965] p-3 rounded-full z-10 hover:text-[#e6ebe0] hover:bg-[#1b4965]"
        >
          <FaArrowLeft />
        </button>

        {/* Carousel content */}
        <div ref={carouselRef} className="carousel carousel-center max-w-full p-4 space-x-4 rounded-box overflow-x-auto">
          {Projects.Projects.map((project, index) => (
            project.Title && project.Year && project.Description && project.Technologies.length > 0 && (
              <ProjectCard
                key={index}
                Year={project.Year}
                Title={project.Title}
                Description={project.Description}
                Technologies={project.Technologies}
                Link={project.Link}
              />
            )
          ))}
        </div>

        {/* Right arrow (hidden on small screens) */}
        <button
          onClick={scrollRight}
          className="hidden md:block absolute right-[-2rem] top-1/2 transform -translate-y-1/2 border border-[#1b4965] bg-[#e6ebe0] text-[#1b4965] p-3 rounded-full z-10 hover:text-[#e6ebe0] hover:bg-[#1b4965]"
        >
          <FaArrowRight />
        </button>
      </div>
    </>
  );
}

export default Carosoul;
