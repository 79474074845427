import React from 'react'
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import NavBar from './NavBar'

const Oops = () => {
  return (
    <>
    <div className="flex justify-center flex-col mx-4 md:mx-40 text-center pt-5 md:pt-10 md:h-[100vh]">
        <h1 className="text-3xl md:text-6xl p-4 md:p-20">Oops! I don't have a link for this one! sorry.</h1>
        <Link className='w-40 btn bg-[#e6ebe0] text-black hover:text-[#1b4965] hover:bg-[#e6ebe0] hover:border-[#5D576B] mx-auto'  to="/">Go back</Link>
    </div>
    </>
  )
}

export default Oops