import React from 'react'
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Oops from './Components/Oops';
import About from './Pages/About';
import Home from './Pages/Home';
import Projects from './Pages/Projects';
import Resume from './Pages/Resume';
import NavBar from './Components/NavBar';
import ScrollToTop from './ScrollToTop';
import AnimatedCursor from "react-animated-cursor"

const App = () => {
  return (
    <main>
      {/* <div className='cursor hidden lg:block'>
      <AnimatedCursor
      color='27,73,101'
      showSystemCursor='true'
      trailingSpeed={2}/>
      </div> */}
      <Router>
        <NavBar/>
        <ScrollToTop/>
        <div>
            <Routes>
                  <Route path="/" element={<Home/>} />
                  <Route path="/projects" element={<Home/>} />
                  <Route path="/resume" element={<Home/>} />
                  <Route path="/about" element={<Home/>} />
                  <Route path="/Oops" element={<Oops/>} />
            </Routes>
      </div>
      </Router>
    </main>
  )
}

export default App